/* eslint-disable import/no-cycle */
import {FilterEqOperation, FilterType, IFilterDTO, IFilterModel, IFilterOption} from '../types/galleryTypes';
import {CollectionFilterModel} from '../models/CollectionFilterModel';
import {MultiCollectionFilterModel} from '../models/MultiCollectionFilterModel';
import _ from 'lodash';
import {FilterConfigsService} from './FilterConfigsService';

export class CollectionFiltersService {
  private readonly collectionFilterModels: (MultiCollectionFilterModel | CollectionFilterModel)[] = [];
  private readonly FILTER_FIELD = 'categories';
  private readonly isMultiCollectionFilter: boolean;

  constructor(
    private mainCollectionId: string,
    private readonly filterConfigsService: FilterConfigsService,
    isCategoryPage?: boolean
  ) {
    this.isMultiCollectionFilter = !!isCategoryPage;
  }

  public createCollectionFilterModel(filterType: FilterType.COLLECTION, filterOptions: IFilterOption[]): IFilterModel {
    const enabledFilterOptions = this.getEnabledCollectionFilterOptions(filterOptions);
    const title = this.filterConfigsService.getFilterConfigTitle(filterType);
    const collectionFilterModel = this.isMultiCollectionFilter
      ? new MultiCollectionFilterModel(filterType, title, enabledFilterOptions)
      : new CollectionFilterModel(filterType, title, enabledFilterOptions, this.mainCollectionId);
    this.collectionFilterModels.push(collectionFilterModel);

    return collectionFilterModel;
  }

  public createCustomCollectionFilterModels(
    filterType: FilterType.CUSTOM_COLLECTION,
    filterOptions: IFilterOption[]
  ): MultiCollectionFilterModel[] {
    const customCollectionFilterConfigs = this.filterConfigsService.getCustomCollectionFilterConfigs();

    const customCollectionFilterModels = customCollectionFilterConfigs.map((filterConfig) => {
      const enabledFilterOptions = this.filterConfigsService.getEnabledFilterOptionsFromFilterConfig(
        filterOptions,
        filterConfig
      );
      return new MultiCollectionFilterModel(filterType, filterConfig.filterTitle, enabledFilterOptions);
    });

    this.collectionFilterModels.push(...customCollectionFilterModels);

    return customCollectionFilterModels;
  }

  public toDTOs(): IFilterDTO[] {
    const dtos: IFilterDTO[] = [];
    const selectedCollectionFilters = this.getSelectedCollectionFilters();
    const selectedCustomCollectionFilters = this.getSelectedCustomCollectionFilters();
    const collectionFilterOperation = this.isMultiCollectionFilter ? FilterEqOperation.IN : FilterEqOperation.IN_ALL;

    selectedCollectionFilters.forEach((selectedFilter) => {
      dtos.push(this.createCollectionDTO(collectionFilterOperation, selectedFilter.activeOptions));
    });

    selectedCustomCollectionFilters.forEach((selectedFilter) => {
      dtos.push(this.createCollectionDTO(FilterEqOperation.IN, selectedFilter.activeOptions));
    });

    return dtos;
  }

  public getEnabledCollectionFilterOptions(filterOptions: IFilterOption[]): IFilterOption[] {
    let result = _.cloneDeep(filterOptions);
    const collectionFilterConfigs = this.filterConfigsService.getCollectionFilterConfigs();

    if (collectionFilterConfigs.length !== 0) {
      result = this.filterConfigsService.getEnabledFilterOptionsFromFilterConfig(
        filterOptions,
        collectionFilterConfigs[0]
      );
    }

    if (this.isMultiCollectionFilter) {
      result = result.filter((c) => c.key !== this.mainCollectionId);
    }

    return result;
  }

  public getSelectedCustomCollectionFilters(): MultiCollectionFilterModel[] {
    const customCollectionFilterModels = this.collectionFilterModels.filter(
      (fm) => fm.filterType === FilterType.CUSTOM_COLLECTION
    ) as MultiCollectionFilterModel[];

    return customCollectionFilterModels.filter((fm) => fm.hasActiveOptions());
  }

  public shouldSpecificCollectionQuery(filter: IFilterDTO): boolean {
    return filter.field === this.FILTER_FIELD && filter.op === FilterEqOperation.IN_ALL && filter.values.length === 1;
  }

  private getSelectedCollectionFilters(): IFilterModel[] {
    const collectionFilterModels = this.collectionFilterModels.filter(
      (fm) => fm.filterType === FilterType.COLLECTION
    ) as any[];

    return collectionFilterModels
      .filter((fm) => fm.hasActiveOptions() && fm.activeOptions !== this.mainCollectionId)
      .map((fm) => {
        const filter = _.cloneDeep(fm);
        filter.activeOptions = this.isMultiCollectionFilter ? [...filter.activeOptions] : [filter.activeOptions];
        return filter;
      });
  }

  private createCollectionDTO(filterEqOperation: FilterEqOperation, collectionIds) {
    return {
      field: this.FILTER_FIELD,
      op: filterEqOperation,
      values: collectionIds,
    };
  }

  public setMainCollectionId = (mainCollectionId: string) => {
    this.mainCollectionId = mainCollectionId;
  };
}
